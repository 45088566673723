<template>
  <safe-img
    class="thumbnail"
    :class="{ 'thumbnail--placeholder': hasPlaceholder }"
    :src="src"
    :placeholder="placeholder"
    :alt="alt"
    @src-changed="hasPlaceholder = $event.isPlaceholder"
  />
</template>

<script>
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  name: 'CardThumbnail',
  components: {
    SafeImg,
  },
  props: {
    src: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hasPlaceholder: false,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
  .thumbnail {
    height: 140px;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
    &--placeholder {
      object-fit: contain;
    }
  }
</style>
