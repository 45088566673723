<template>

  <base-events-horizontal-list
    v-slot="{ item }"
    :load-next-page="loadNextPage"
    :load-previous-page="loadPreviousPage"
    @events-updated="$emit('events-updated', $event)"
    :force-events="forceEvents"
    :key="reload"
    :reload="reload"
    :customfields="customfields"
    :classifiers="classifiers"
    :value="value"
  >
    <event-card :event="item" :can-view="canView" />
  </base-events-horizontal-list>

  
</template>

<script>
import { EventType, EventPackages } from "@copernicsw/community-common";
import {
  EVENTS_STORE_MODULE_NAME,
  EVENTS_ACTIONS,
} from "@/views/apps/events/constants/events-store-constants";
import eventsStoreModule from "@/views/apps/events/store/eventsStoreModule";
import OrderBy from "@core/constants/OrderBy";
import BaseEventsHorizontalList from "./BaseEventsHorizontalList.vue";
import EventCard from "./EventCard.vue";

export default {
  name: "EventsHorizontalList",
  components: {
    BaseEventsHorizontalList,
    EventCard,
  },
  props: {
    reload: Number,
    forceEvents: Boolean,
    peopleKey: {
      type: String,
      default: "",
    },
    customfields: {
      default: null,
    },
    classifiers: {
      default: null,
    },
    canView: Boolean,
    value: String,
  },
  computed: {
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key;
    },
  },
  async created() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
  },
  methods: {
    async loadNextPage({ lastDate }) {
      const response = await this.$store.dispatch(
        EVENTS_ACTIONS.fetchEventList,
        {
          visibleOncommunityKey: this.currentCollectiveKey,
          excludeTypes: [EventType.Meeting],
          excludeStreaming: true,
          classifiers: this.classifiers,
          customFields:
            this.customfields != null &&
            Object.keys(this.customfields).length > 0
              ? this.customfields
              : null,
          sinceStartDate: (lastDate || Date.now()) + 1000,
          count: 16,
          orderByDate: OrderBy.ASC,
          eventPackage: EventPackages.EventsList,
          ...(this.peopleKey ? { peopleKey: this.peopleKey } : null),
        }
      );
      return response.data.data;
    },
    async loadPreviousPage({ firstDate }) {
      const response = await this.$store.dispatch(
        EVENTS_ACTIONS.fetchEventList,
        {
          visibleOncommunityKey: this.currentCollectiveKey,
          excludeTypes: [EventType.Meeting],
          excludeStreaming: true,
          classifiers: this.classifiers,
          customFields:
            this.customfields != null &&
            Object.keys(this.customfields).length > 0
              ? this.customfields
              : null,
          untilStartDate: (firstDate || Date.now()) - 1000,
          count: 16,
          orderByDate: OrderBy.DESC,
          eventPackage: EventPackages.EventsList,
          ...(this.peopleKey ? { peopleKey: this.peopleKey } : null),
        }
      );

      return response.data.data.reverse();
    },
  },
};
</script>
