<template>
  <div>
    <b-link
      :to="eventLocation"
    >
      <div class="ticket">
        <div class="top">
          <card-thumbnail
            :src="thumbnailSrc"
            :placeholder="EventsPlaceholder"
            :alt="translatedTitle"
          />
          <div class="title">
            {{ translatedTitle }}
          </div>
          <div v-if="createdBy" :class="{ 'createdBy-short': !isLonger, 'createdBy-long': isLonger }">
            <safe-img class="logo badge-minimal" :src="computedLogo" :placeholder="organizationPlaceholder" />
            <div class="offered-by h-1r">
              <p :class="{ 'main': !isLonger, 'small-title': isLonger }">
                {{ createdBy.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="rip" />
        <event-card-period :event="event" tag="div" class="bottom" />
      </div>
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import CardThumbnail from '@core/components/card-thumbnail/CardThumbnail.vue';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import EventCardPeriod from './EventCardPeriod.vue';

export default {
  components: {
    BLink,
    CardThumbnail,
    EventCardPeriod,
    SafeImg,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    canView: Boolean,
  },
  setup() {
    return { EventsPlaceholder };
  },
  computed: {
    translatedTitle() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.event.title);
    },
    isLonger() {
      return !!this.createdBy && this.createdBy.name?.length >= 40;
    },
    createdBy() {
      return this.event.ownedByCommunity || this.event.ownedByMember;
    },
    organizationPlaceholder() {
      return OrganizationsPlaceholder;
    },
    computedLogo() {
      return getImageResource(this.createdBy.logoURL);
    },
    thumbnailSrc() {
      return getImageResource(this.event.thumbnailURL);
    },
    eventLocation() {
      return this.canView ? { name: 'event', params: { id: this.event.key, communityId: this.$route.params.communityId } } : null;
    },
  },
};
</script>
